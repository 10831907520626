import React, { Fragment } from 'react';

export const RenderTextField = props => {
  const { errorMessage, isInvalid, placeholder } = props;
  return (
    <Fragment>
      <input className={isInvalid ? 'inputError' : ''} {...props} />
      <label>
        {placeholder} <span>*</span>
      </label>
      {isInvalid && <span className="error">{errorMessage}</span>}
    </Fragment>
  );
};

export const RenderSelect = props => {
  const {
    options,
    onChange,
    selectedValue,
    defaultValue,
    name,
    errorMessage,
    isInvalid,
    onBlur,
    value,
  } = props;
  return (
    <Fragment>
      <select
        name={name}
        onChange={onChange}
        onFocus={onBlur}
        defaultValue={defaultValue}
        className={isInvalid ? 'inputError' : ''}
      >
        <option selected="selected" value="">
          {defaultValue}
        </option>
        {options &&
          options.map((item, index) => {
            return (
              <option
                key={`${index}_${Math.random()}`}
                selected={selectedValue && selectedValue == item.value}
                value={item.value.trim()}
              >
                {item.label}
              </option>
            );
          })}
      </select>
      {isInvalid && <span className="error">{errorMessage}</span>}
    </Fragment>
  );
};

export const RenderTextAreaField = props => {
  const { errorMessage, isInvalid, onBlur, placeholder } = props;
  return (
    <Fragment>
      <textarea
        name={props.name}
        rows={props.rows}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        onBlur={onBlur}
        className={isInvalid ? 'inputError' : ''}
      />
      <label>
        {placeholder} <span>*</span>
      </label>
      {isInvalid && <span className="error">{errorMessage}</span>}
    </Fragment>
  );
};
