import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  background: #ff22ee;
  border: 2px solid #ff22ee;
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  min-width: 250px;
  height: 65px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px;
  transition: all 0.5s ease 0s;
  position: relative;
  &:before {
    ${props => {
      if (props.isLoading) {
        return `
        content:"";
        border: 8px solid #e4e4e4;
        border-radius: 50%;
        border-top: 8px solid #ff22ee;
        width: 20px;
        height:20px;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
        position: absolute;
        top: 12px;
        right: 12px;
          @-webkit-keyframes spin {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `;
      }
    }}
  }
  svg {
    fill: #ffffff;
    position: relative;
    left: 8px;
    transition: all 1s ease 0s;
  }
  &:disabled {
    opacity: 0.6;
    &:hover:after {
      opacity: 1 !important;
      @media (max-width: 965px) {
        opacity: 0 !important;
      }
    }
  }
  &:focus,
  &:hover {
    background: #ff22ee;
    color: #fff;
    outline: none;
    svg {
      fill: #fff;
      left: 40px;
    }
  }
  @media (max-width: 965px) {
    min-width: 100%;
    justify-content: center;
    margin-bottom: 25px;
    font-size: 26px;
    padding: 0;
  }
  @media (max-width: 735px) {
  }
`;

const Button = props => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};

export default Button;
