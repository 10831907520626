const scrollToView = props => {
  const { id, block } = props;
  const section = document.getElementById(id);
  section &&
    section.scrollIntoView({
      behavior: 'smooth',
      block: block || 'start',
      inline: 'nearest',
    });
    // section.scrollTop += 750;
};

export default scrollToView;
